import type { BlockSeenOn } from 'types/block-seen-on';
import React from 'react';
import { Link, Wrap } from '@leafwell/components';
import Image from 'next/image';
import HeadingBody from './heading-body';

const SeenOn: React.FC<BlockSeenOn> = ({ title, items }) => {
  return (
    <Wrap className="gap-y-6 md:gap-y-10">
      {title && <HeadingBody level="2" title={title} className="text-center" />}
      <div className="flex flex-wrap justify-center	items-center gap-y-8 gap-x-3">
        {items.map(({ logo, url }) => {
          const ItemWrap = url ? Link : 'div';
          return (
            <ItemWrap
              key={logo.id}
              className="w-32 md:w-48"
              {...(url
                ? { href: url, target: ':blank', rel: 'noopener noreferrer' }
                : {})}
            >
              <Image
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
                height={logo.height}
                className="w-28 md:w-36 mx-auto"
                quality={50}
              />
            </ItemWrap>
          );
        })}
      </div>
    </Wrap>
  );
};

export default SeenOn;
